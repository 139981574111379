import {
    Component, Input, OnInit
} from '@angular/core';
import { HttpService } from '../../services/http.service';
import {
    ActivatedRoute, Router 
} from '@angular/router';

@Component({
    selector: 'app-borrow-widget',
    templateUrl: './borrow-widget.component.html',
    styleUrl: './borrow-widget.component.scss'
})

export class BorrowWidgetComponent implements OnInit {
    @Input() currentTab: string = 'drawdown';

    theme: any;

    @Input() pool_id: string = '';
    @Input() drawdown_id: string = '';

    constructor(private httpService: HttpService, private route: ActivatedRoute, private router: Router) {
    }

    ngOnInit(): void {
        this.getCurrentTheme();

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: this.currentTab,
                    drawdown_id: this.drawdown_id
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    changedTab(tab: any) {
        this.currentTab = tab;

        this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab,
                    drawdown_id: this.drawdown_id
                },
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
