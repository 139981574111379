<div class="app-disclosure">
    <div>
        By clicking “I Agree” below, you agree to be bound by the terms of this Agreement. As such, you fully
        understand that:
    </div>

    <div class="points">
        <ul>
            <li>
                <a href="https://credible.finance" target="_blank">Credible Finance</a> is a blockchain-based
                decentralized finance project. You are participating at your own
                risk.
            </li>

            <li>
                <a href="https://credible.finance" target="_blank">Credible Finance</a> is offered for use “as is” and
                without any guarantees regarding security. The protocol is
                made up of immutable
                code and can be accessed through a variety of user interfaces.
            </li>

            <li>
                No central entity operates the <a href="https://credible.finance" target="_blank">Credible Finance</a>
                protocol. Decisions related to the protocol are governed by
                a dispersed group
                of participants who collectively govern and maintain the protocol.
            </li>

            <li>
                <a href="https://credible.finance" target="_blank">Credible Finance</a> does not unilaterally offer,
                maintain, operate, administer, or control any trading
                interfaces. The only
                user interfaces maintained by <a href="https://credible.finance" target="_blank">Credible Finance</a>
                are the governance and liquidity interfaces herein.
            </li>
        </ul>
    </div>

    <div class="actions">
        <cf-button (click)="rejectConditions()" type="outline" label="I reject" [loading]="reject_loading"></cf-button>

        <cf-button (click)="acceptConditions()" type="solid" label="I agree" [loading]="accept_loading"></cf-button>
    </div>
</div>