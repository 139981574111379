<div class="connect_wallet container-fluid">
    <div class="container gx-0">
        <span class="register-heading">You will be asked to sign a transaction to authenticate you in
            our platform.<br />No funds will be moved and you will not be charged
            a transaction fee.
        </span>

        <div class="ledger">
            <cf-checkbox (changed)="ledgerChanged($event)" name="I have a ledger device"></cf-checkbox>
        </div>

        <div class="wallets">
            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'phantom'}"
                (click)="changeProvider('phantom')">
                <div class="provider-name">
                    <img src="https://assets.credible.finance/Phantom-Icon-Transparent-Purple.png" />
                    <span>Phantom</span>
                </div>
                <span *ngIf="!phantomDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                    Not Detected
                </span>
                <span *ngIf="phantomDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                    Detected
                </span>
            </div>

            <div class="wallet-container" [ngClass]="{'wallet-container-active': current_provider === 'solflare'}"
                (click)="changeProvider('solflare')">
                <div class="provider-name">
                    <img src="../../assets/images/solflare.png" />
                    <span>Solflare</span>
                </div>
                <img src= />
                <span *ngIf="solflareDetected" class="wallet-detected">
                    <i class="fa-solid fa-circle-check"></i>
                    Detected
                </span>

                <span *ngIf="!solflareDetected" class="wallet-not-detected">
                    <i class="fa-solid fa-circle-xmark"></i>
                    Not detected
                </span>
            </div>
        </div>

        <div class="row mb-3" *ngIf="!showControls.connectButton">
            <div class="col-md-12 current-wallet-address">
                <cf-input class="web" [value]="wallet_address" label="Wallet address" type="text" [copy]="true"
                    placeholder="Wallet address" (valueChanged)="walletChanged($event)" [formGroup]="registerForm"
                    [readonly]="true" [control]="$any(registerForm).get('wallet_address')">
                </cf-input>

                <cf-input class="mobile" [value]="this.httpService.sliceAddress(wallet_address, 8)"
                    label="Wallet address" type="text" [copy]="true" placeholder="Wallet address"
                    (valueChanged)="walletChanged($event)" [formGroup]="registerForm" [readonly]="true"
                    [control]="$any(registerForm).get('wallet_address')">
                </cf-input>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'phantom'">
            <div class="action" *ngIf="!phantomDetected && width > 600">
                <cf-button label="Download Phantom" (click)="phantomService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="!phantomDetected && width <= 600">
                <cf-button label="Connect Phantom" (click)="connectWalletMobile()" type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.connectButton">
                <cf-button id="cf-btn" label="Connect Phantom" (click)="phantomService.connectWallet()">
                </cf-button>
            </div>

            <div class="action" *ngIf="phantomDetected && showControls.disconnectButton">
                <cf-button id="cf-btn" label="Disconnect Phantom" (click)="phantomService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>

        <div class="actions" *ngIf="current_provider === 'solflare'">
            <div class="action" *ngIf="!solflareDetected">
                <cf-button label="Download Solflare" (click)="solflareService.downloadWallet()"
                    type="outline"></cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.connectButton">
                <cf-button id="cf-btn" label="Connect Solflare" (click)="solflareService.connectWallet()">
                </cf-button>
            </div>

            <div class="action" *ngIf="solflareDetected && showControls.disconnectButton">
                <cf-button id="cf-btn" label="Disconnect Solflare" (click)="solflareService.disconnectWallet()"
                    type="outline"></cf-button>
            </div>
        </div>
    </div>

    <div class="controls">
        <cf-button label="Previous" type="outline" [disabled]="true"></cf-button>
        <cf-button label="Next" (click)="nextHandler()" type="outline"
            [disabled]="httpService.user.wallet_address === ''"></cf-button>
    </div>
</div>