import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, HostListener, Input, OnInit
} from '@angular/core';

import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import {MatDialog} from '@angular/material/dialog';

import { getWindow } from 'ssr-window';

@Component({
    selector: 'app-vault-allocation',
    templateUrl: './vault-allocation.component.html',
    styleUrl: './vault-allocation.component.scss'
})

export class VaultAllocationComponent implements OnInit {
    loading: boolean = false;
    items: Array<any> = [];
    total_count: number = 0;
    page: number = 0;
    limit: number = 10;

    @Input() pool_id: string | null = null;

    window: Window;

    theme: any;
    width: any;

    constructor(public httpService: HttpService, private cfAlertService: CfAlertService,
        public dialog: MatDialog) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.getVaultAllocation();

        this.getCurrentTheme();

        this.width = this.window.innerWidth;
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    getVaultAllocation() {
        this.loading = true;

        const body = {
            page: this.page,
            limit: this.limit,
            vault_id: this.pool_id
        };

        this.httpService.getVaultAllocation(body).subscribe((res: any) => {
            this.items = res.data;
            this.total_count = res.total_count;

            this.loading = false;
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.loading = false;

            this.cfAlertService.showError(err);
        });
    }

    pageChanged(event: any) {
        this.page = event;
        this.getVaultAllocation();
    }
}