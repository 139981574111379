import {
    Component, OnInit 
} from '@angular/core';
import {
    ActivatedRoute, Router 
} from '@angular/router';

@Component({
    selector: 'app-transactions',
    templateUrl: './transactions.component.html',
    styleUrl: './transactions.component.scss'
})
export class TransactionsComponent implements OnInit {
    currentTab = 'my-supply';

    tabs = [{
        name: 'Supply',
        key: 'my-supply'
    }, {
        name: 'Earnings',
        key: 'my-earnings'
    }, {
        name: 'Withdrawals',
        key: 'my-withdrawals'
    }, {
        name: 'Drawdowns',
        key: 'my-drawdowns'
    }, {
        name: 'Repayments',
        key: 'my-repayments'
    }];

    constructor(private route: ActivatedRoute, private router: Router) {

    }

    ngOnInit(): void {
        this.currentTab = this.tabs[0].key;

        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (this.tabs.find(t => t.key === tab)) 
                    this.currentTab = tab;
            }
        });
    }

    changeTab(tab: string) {
        this.currentTab = tab;

        this.router.navigate(
            [], 
            {
                relativeTo: this.route,
                queryParams: {
                    tab: tab
                }, 
                queryParamsHandling: 'merge' // remove to replace all query params by provided
            }
        );
    }
}
