import {
    Connection, PublicKey 
} from '@solana/web3.js';
import { networks } from '../app.config';

async function getWalletBalanceMainAddress(cluster: any, wallet_address: string, currency: string): Promise<number> {
    if (wallet_address === '')
        return 0;

    try {
        console.log('getWalletBalanceMainAddress : input => ', {
            cluster,
            wallet_address,
            currency
        });

        const source = new PublicKey(wallet_address);

        const connection = new Connection(networks[cluster].rpc);

        const amount = await connection.getBalance(source);

        console.log('getWalletBalanceMainAddress : amount => ', amount);

        const balance = amount / Math.pow(10, 9);

        console.log('getWalletBalanceMainAddress : balance => ', balance);

        return balance;
    } 
    catch (err) {
        return 0;
    }
}

export default getWalletBalanceMainAddress;