import {
    Component, OnInit, Input
} from '@angular/core';
// import { TOASTER_POSITION, TOASTER_TYPE } from 'src/app/constants/app-constants';
// import { ToasterService } from 'src/app/shared/services/toaster.service';

import { stringify } from 'query-string';

import getSignature from '../../../utils/getSignature';
import {
    apiKey, secretKey, merchant_redirect_url, paynormaKycBaseUrl
} from '../app.config';
import { HttpService } from '../../services/http.service';
@Component({
    selector: 'app-kyc',
    templateUrl: './kyc.component.html',
    styleUrls: [ './kyc.component.scss' ]
})
export class KycComponent implements OnInit {
    @Input() pool_id: any;

    client_id: any;
    isKycCompleted: boolean = false;
    currentUser: any;
    selfie_imgBase64: any;
    loading: boolean = false;
    showPendingStatus: boolean = false;
    redirect_url:any;
    constructor(
        // private fb: FormBuilder,
        public httpService: HttpService
        // private toasterService: ToasterService,
    ) { }

    ngOnInit() {
        console.log('pool_id', this.pool_id);
        this.showPendingStatus = false;
        // this.getToken();
        // this.attachSocketEvents();
        this.currentUser = this.httpService.getCurrentUser();
        this.isKycCompleted = this.currentUser?.kyc_status === 1 ? true : false;
        this.showPendingStatus = this.isKycCompleted ? false : true;

        this.redirect_url = merchant_redirect_url + this.pool_id;
        console.log(' this.isKycCompleted', this.isKycCompleted);
        console.log(' this.showPendingStatus', this.showPendingStatus);
    }

    // attachSocketEvents() {
    //     this.connectKycData();
    //     this.httpService.socket.on('connect', () => {
    //     });
    // }

    // connectKycData() {
    //     this.httpService.socket.on('user_updated_data', (data:any) => {
    //         if (data) 
    //             this.httpService.user.kyc_status = data.kyc_status;
    //     });
    // }

    generateUrl() {
        const api_key = apiKey;
        const secret_key = secretKey;
        const merchant_customer_id = this.httpService.user.user_id;
        const nonce = new Date().getTime();
        const recvwindow = 60000;

        const signatureData: any = {
            api_key,
            merchant_customer_id,
            nonce,
            recvwindow
        };

        // if (merchant_redirect_url)
        signatureData.merchant_redirect_url = this.redirect_url;

        const signature = getSignature(signatureData, secret_key);

        const queryParams: any = {
            ...signatureData,
            signature,
            add_bank: false
        };

        // if (signatureData.merchant_redirect_url)
        queryParams.merchant_redirect_url = signatureData.merchant_redirect_url;

        const queryString = stringify(queryParams);

        console.log('queryString', queryString);
       
        window.location.href = `${paynormaKycBaseUrl}/?${queryString}`;
    }
    loadKYCWidget() {
        console.log('Loading widget');
      
        this.generateUrl();
    }
}

