import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, Output, HostListener, 
    EventEmitter
} from '@angular/core';
import {
    FormGroup, FormBuilder 
} from '@angular/forms';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import WalletEvent from '../../../models/WalletEvent';
import { HttpService } from '../../../services/http.service';
import { LocalStorageService } from '../../../services/localstorage.service';
import { PhantomService } from '../../../services/phantom.service';
import { SolflareService } from '../../../services/solflare.service';
import { WalletService } from '../../../services/wallet.service';
import { RECAPTCHA_SITE_KEY } from '../../app.config';
import { Router} from '@angular/router';

declare let grecaptcha: any;

@Component({
    selector: 'app-connect-wallet',
    templateUrl: './connect-wallet.component.html',
    styleUrls: [ './connect-wallet.component.scss' ]

})
export class ConnectWalletComponent implements OnInit {
    timeLeft!: number;
    wallet_address: string = '';

    showControls = {
        connectButton: true,
        sendCodeButton: false,
        registerButton: false,
        disconnectButton: false
    };

    phantomDetected: boolean = false;
    solflareDetected: boolean = false;
    enableResendCodeAction: boolean = true;
    registerForm!: FormGroup;
    otp: string | undefined = undefined;
    current_provider: string = 'phantom';
    window: Window;
    width: any;
    ledger: boolean = false;

    referral_code: string = '';

    @Output() connected = new EventEmitter<any>();
    @Output() next = new EventEmitter<any>();

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private localStorageService: LocalStorageService,
        private fb: FormBuilder,
        public phantomService: PhantomService,
        public solflareService: SolflareService,
        private walletService: WalletService
       
    ) {
        this.window = getWindow();

        this.registerForm = this.fb.group({
            wallet_address: [ '' ],
            signed_message: [ '' ],
            signature: [ '' ]
        });
    }

    ngOnInit(): void {
        this.width = this.window.innerWidth;
      
        this.phantomDetected = this.phantomService.isPresent();
        this.solflareDetected = this.solflareService.isPresent();
        
        this.walletService.ledger = false;
      
        this.subscribeWalletObservables();
    }

    connectWalletMobile() {
        const callbackUrl = 'https://credible.finance/';

        const deepLink = `https://phantom.app/ul/browse/${callbackUrl}?ref=${callbackUrl}`;

        return this.window.location.href = deepLink;
    }

    async checkWalletAddress(address: string, provider: string, referral_code: string) {
        console.log('checkWalletAddress', address);

        const recaptcha = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
            action: 'checkWalletAddress'
        });

        const body = {
            wallet_address: this.wallet_address,
            referral_code,
            recaptcha,
            signature: this.registerForm.controls['signed_message'].value,
            provider: provider
        };

        console.log(body);

        this.httpService.checkWalletAddress(body).subscribe({
            next: (res: any) => {
                console.log(address, res.data);

                this.localStorageService.setItem('token', res.headers.get('token') || '');
                this.walletService.current_provider = provider;

                this.httpService.getCurrentUser().then(() => {
                    this.connected.emit({
                        provider,
                        wallet_address: address
                    });
                });
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        });
    }

    async checkWalletAddressV2(address: string, provider: string, referral_code: string) {
        console.log('checkWalletAddressV2', address);

        const recaptcha = await grecaptcha.execute(RECAPTCHA_SITE_KEY, {
            action: 'checkWalletAddressV2'
        });

        const body: any = {
            wallet_address: this.wallet_address,
            referral_code,
            recaptcha,
            provider: provider,
            ledger: this.walletService.ledger
        };

        if (this.walletService.ledger) {
            const serializedTx = Array.from(this.registerForm.controls['signed_message'].value);

            body.serializedTx = serializedTx;
        }

        else {
            const signature = Array.from(this.registerForm.controls['signature'].value);

            body.signature = signature;
        }

        console.log(body);

        this.httpService.checkWalletAddressV2(body).subscribe({
            next: (res: any) => {
                console.log(address, res.data);

                this.localStorageService.setItem('token', res.headers.get('token') || '');
                this.walletService.current_provider = provider;

                this.httpService.getCurrentUser().then(() => {
                    this.connected.emit({
                        provider,
                        wallet_address: address
                    });
                });
            },
            error: (err: HttpErrorResponse) => {
                console.error(err);

                this.cfAlertService.showError(err);
            }
        });
    }

    walletChanged(value: string) {
        console.log('walletChanged', value);

        this.wallet_address = value;
    }

    subscribeWalletObservables() {
        this.walletService.getEvent().subscribe(async (event: WalletEvent) => {
            console.log('subscribeWalletObservables', event);

            switch (event.name) {
                case 'connect':

                    this.wallet_address = event.metadata.wallet_address;

                    this.showControls.connectButton = false;
                    this.showControls.disconnectButton = true;
                    this.showControls.sendCodeButton = true;

                    break;

                case 'signMessage':
                    this.signMessage(event);

                    break;

                case 'disconnect':

                    this.cfAlertService.showSuccess(`${this.httpService.sliceAddress(this.wallet_address)}... account disconnected`);
                    this.wallet_address = '';

                    this.showControls.connectButton = true;
                    this.showControls.disconnectButton = false;
                    this.showControls.sendCodeButton = false;

                    this.router.navigate([ '/' ]);

                    break;

                case 'accountChanged':
                    if (event.metadata.new_address) {
                        console.log(`Switched to account ++ ${event.metadata.new_address}`);
                        
                        this.router.navigate([ '/' ]);
                    }

                    break;

                default:
                    console.log('Unknown event', event);
            }
        });
    }

    async signMessage(event: WalletEvent) {
        if (event.metadata.status === 'failure')
            return this.cfAlertService.showMessage('Invalid signature');

        else if (event.metadata.status === 'success') {
            console.log('event.metadata.signature', event.metadata.signature);

            if (this.walletService.ledger)
                this.registerForm.controls['signed_message'].setValue(event.metadata.serializedTx);

            else
                this.registerForm.controls['signature'].setValue(event.metadata.signature);

            this.checkWalletAddressV2(this.wallet_address, event.provider, this.referral_code);
        }
    }

    changeProvider(provider: string) {
        this.current_provider = provider;
    }

    nextHandler() {
        this.next.emit();
    }

    ledgerChanged(event: any) {
        console.log('ledgerChanged', event);
        
        this.ledger = event;
        this.walletService.ledger = event;
    }
}