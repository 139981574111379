<div class="app-not-found" [ngClass]="theme === 'dark' ? 'dark': ''">
    <div class="app-not-found-container container">
        <div class="http-status-code-container">
            <span class="http-status-code">4</span>
            <span class="http-status-code">0</span>
            <span class="http-status-code">4</span>
        </div>

        <div class="row>">
            <div class="col-12 heading-1">
                <span>There's NOTHING here...</span>
            </div>
        </div>

        <div class="row>">
            <div class="col-12 heading-2">
                <span>...maybe the page you are looking for isn't found or it
                    never existed</span>
            </div>
        </div>

        <div class="row">
            <div class="col-12 mt-4">
                <cf-button label="Go back to home" (click)="goHome()" type="outline"></cf-button>
            </div>
        </div>
    </div>