<div class="app-new-earning" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div *ngIf="
            !loading &&
            httpService.user.wallet_address !== '' &&
            !show_no_investments
        ">

        <div class="row mt-2">
            <cf-dropdown [theme]="theme" [options]="investmentDropdownOptions" (change)="changeInvestment($event)"
                label="Select Investment" [showLogo]="false" [selected]="lending_id" [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <cf-input-currency-dropdown [theme]="theme" [readonly]="true" [options]="currencyDropdownOptions"
                [value]="amount" [class]="'input'" label="Amount" [selected]="currency" type="number"
                (valueChanged)="amountChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
            <span class="equivalent-amount">
                {{ amount | number : httpService.getPrecision(currency) }}
                {{ currency | uppercase }}
                ≈
                {{ amount_usd | number : httpService.getPrecision("usd") }}
                USD
            </span>
            <cf-input class="mt-2" [theme]="theme" [label]="'CRED points'" [value]="cred_points.toString()"
                [copy]="false" [readonly]="true"></cf-input>
        </div>

        <div class="row mt-2" *ngIf="vault_dropdown_options.length > 0">
            <cf-dropdown [theme]="theme" [options]="vault_dropdown_options" [class]="'input'" label="Select Vault"
                [selected]="selected_vault" (change)="changeVault($event)">
            </cf-dropdown>
        </div>

        <div class="actions">
            <div *ngIf="
                    this.kyc_required &&
                    httpService.user.wallet_address !== '' &&
                    httpService.user?.kyc_status !== 1
                ">
                <app-kyc [pool_id]="this.pool_id" />
            </div>
            <div *ngIf="
                    httpService.user.wallet_address !== '' &&
                    (!this.kyc_required || httpService.user?.kyc_status === 1)
                ">
                <div class="action">
                    <div *ngIf="!btn_loading">
                        <cf-button label="Claim Earnings" type="solid"
                            [disabled]="httpService.user.wallet_address === ''" (click)="claimInterest()"></cf-button>
                    </div>
                    <div *ngIf="btn_loading">
                        <cf-loader></cf-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div *ngIf="httpService.user.wallet_address === ''">
        <cf-button label="Connect wallet" type="solid" (click)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_investments" class="no_data">
        <div>No investments</div>
    </div>
</div>