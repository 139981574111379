import * as CryptoJS from 'crypto-js';

function getSignature(message: any, secret_key: string) {
    const message_sorted = Object.keys(message).sort().reduce((acc: any, key) => {
        acc[key] = message[key].toString();

        return acc;
    }, {});

    console.log('message_sorted', message_sorted);

    const message_string = JSON.stringify(message_sorted);

    console.log('message_string', message_string);

    const signature = CryptoJS.HmacSHA256(message_string, secret_key).toString();

    console.log('signature', signature);

    return signature;
}

export default getSignature;
