import {
    Component, EventEmitter, OnInit, Output 
} from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { OnboardingService } from '../../onboarding.service';

@Component({
    selector: 'app-disclosure',
    templateUrl: './disclosure.component.html',
    styleUrl: './disclosure.component.scss'
})
export class DisclosureComponent implements OnInit {
    @Output() completed = new EventEmitter<any>();
    @Output() previous = new EventEmitter<any>();
    accept_loading: boolean = false;
    reject_loading: boolean = false;

    constructor(
        private httpService: HttpService,
        private onboardingService: OnboardingService,
        private cfAlertService: CfAlertService,
        private router: Router) {}
    
    ngOnInit(): void {
        
    }

    acceptConditions() {
        this.accept_loading = true;

        this.onboardingService.acceptTermsAndConditions().subscribe((res: any) => {
            this.accept_loading = false;
            this.httpService.user.terms_and_conditions = true;
            this.cfAlertService.showMessage(res.message);

            this.completed.emit();
        }, (err: HttpErrorResponse) => {
            console.error('terms and conditions update error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.accept_loading = false;
        });
    }

    rejectConditions() {
        this.reject_loading = true;

        this.onboardingService.rejectTermsAndConditions().subscribe((res: any) => {
            this.reject_loading = false;
            this.httpService.user.terms_and_conditions = false;

            this.cfAlertService.showMessage(res.message);

            this.router.navigate([ '/' ]);
        }, (err: HttpErrorResponse) => {
            console.error('terms and conditions update error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.reject_loading = false;
        });
    }
}
