import {
    Component, Input, OnInit
} from '@angular/core';
import { Router} from '@angular/router';
import { getWindow } from 'ssr-window';
import IPool from '../../../interfaces/IPool';
import Pool from '../../../models/Pool';

@Component({
    selector: 'app-lend-default',
    templateUrl: './lend-default.component.html',
    styleUrl: './lend-default.component.scss'
})

export class LendDefaultComponent implements OnInit {
    @Input() pool: IPool = new Pool();

    window: Window;

    constructor(private router: Router) {
        this.window = getWindow();
    }

    ngOnInit(): void {
        console.log('lend-default.component.ts ngOnInit()');
    }

    selectPool(pool: IPool) {
        this.router.navigate([ `/liquidity/${pool.category_id}/${pool.pool_id}` ]);
    }

    redirectToUrl(event: Event, url: string) {
        this.window.open(url, '_blank');

        event.preventDefault();
        event.stopImmediatePropagation();
    }
}