<div class="app-connect-socials container-fluid" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div class="socials">
        <div class="social">
            <div class="description">
                <span>Join us</span>
                <a href="https://t.me/crediblefinance" target="_blank">
                    <i class="fa-brands fa-telegram" matTooltip="Telegram"></i>Telegram
                </a>
            </div>
            <div class="action">
                <cf-button [label]="telegramJoined === true ? 'Joined' : 'Join'" (click)="connectTelegram()"
                    type="outline" [disabled]="telegramJoined ? true : false"
                    [loading]="telegram_join_loading"></cf-button>
            </div>
        </div>

        <div class="social">
            <div class="description">
                <span>Follow us</span>
                <a href="https://twitter.com/crediblefin" target="_blank">
                    <i class="fa-brands fa-twitter" matTooltip="Twitter"></i>Twitter
                </a>
            </div>
            <div class="action">
                <cf-button [label]="twitterJoined === true ? 'Joined' : 'Join'" (click)="connectTwitter()"
                    type="outline" [disabled]="twitterJoined ? true : false"
                    [loading]="twitter_join_loading"></cf-button>
            </div>
        </div>
    </div>

    <div class="controls">
        <cf-button label="Previous" [disabled]="true" type="outline"></cf-button>
        <cf-button label="Next" (click)="next()" type="outline"
            [disabled]="!telegramJoined || !twitterJoined"></cf-button>
    </div>
</div>