<div class="app-new-withdrawal" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div *ngIf="!loading && httpService.user.wallet_address !== '' && !show_no_investments">
        <div class="row mt-2">
            <cf-dropdown [theme]="theme" [options]="investmentDropdownOptions" (change)="changeInvestment($event)"
                label="Select Investment" [showLogo]="false" [selected]="lending_id" [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <cf-input-currency-dropdown [theme]="theme" [readonly]="false" [options]="currencyDropdownOptions"
                [value]="amount" [class]="'input'" label="Amount" [selected]="currency" type="number"
                (valueChanged)="amountChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
            <span class="equivalent-amount">
                Available :
                {{
                investment_info.outstanding_amount
                | number : httpService.getPrecision(currency)
                }}
                {{ currency | uppercase }}
            </span>
        </div>

        <div class="row mt-2" *ngIf="vault_dropdown_options.length > 0">
            <cf-dropdown [theme]="theme" [options]="vault_dropdown_options" [class]="'input'" label="Select Vault"
                [selected]="selected_vault" (change)="changeVault($event)">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <div class="new-withdrawal-summary">
                <div class="summary-item">
                    <span class="summary-heading">Initial investment</span>
                    <span class="summary-value">{{
                        investment_info.amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Withdrawn already</span>
                    <span class="summary-value">{{
                        investment_info.amount -
                        investment_info.outstanding_amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Currently invested</span>
                    <span class="summary-value">{{
                        investment_info.outstanding_amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Fees</span>
                    <span class="summary-value">{{
                        fee | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">You will receive</span>
                    <span class="summary-value">{{
                        received_amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
            </div>
        </div>

        <div class="actions">
            <div *ngIf="
                    this.kyc_required &&
                    httpService.user.wallet_address !== '' &&
                    httpService.user?.kyc_status !== 1
                ">
                <app-kyc [pool_id]="this.pool_id" />
            </div>
            <div *ngIf="
                    httpService.user.wallet_address !== '' &&
                    (!this.kyc_required || httpService.user?.kyc_status === 1)
                ">

                <div class="action">
                    <div *ngIf="!btn_loading">
                        <cf-button label="Withdraw" type="solid" [disabled]="httpService.user.wallet_address === ''"
                            (click)="principalWithdrawal()"></cf-button>
                    </div>

                    <div *ngIf="btn_loading">
                        <cf-loader></cf-loader>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div *ngIf="httpService.user.wallet_address === ''">
        <cf-button label="Connect wallet" type="solid" (click)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_investments" class="no_data">
        <div>No investments</div>
    </div>
</div>