import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import IInvestment from '../../interfaces/IInvestment';
import {
    Connection, Transaction
} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import { WalletService } from '../../services/wallet.service';
import {Router} from '@angular/router';
import IPool from '../../interfaces/IPool';
import { networks } from '../app.config';
import { nullChecker } from '../helpers/nullChecker';

@Component({
    selector: 'app-new-earning',
    templateUrl: './new-earning.component.html',
    styleUrls: [ './new-earning.component.scss' ]

})
export class NewEarningComponent implements OnInit {
    currencyDropdownOptions: Array<IDropdown> = [];
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';

    investmentDropdownOptions: Array<IDropdown> = [];
    investments: Array<IInvestment> = [];

    lending_id: string = '';
    interest_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    pools: Array<IPool> = [];
    poolOptions: Array<IDropdown> = [];

    lender_fee_percentage: number = 0;
    fee: number = 0;
    received_amount: number = 0;
    investment_info: any = {};

    cred_points: number = 0;

    show_no_investments: boolean = false;

    theme: any;

    vault_dropdown_options: Array<IDropdown> = [];
    selected_vault: any;
    selected_vault_id: string = '';
    
    @Input() pool_id: string = '';

    kyc_required: boolean = false;

    constructor(
        
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private walletService: WalletService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log('new-earning.component.ts ngOnInit()');

        this.getCurrentTheme();

        this.getInvestments();
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    getInvestments() {
        this.showLoader();

        this.investmentDropdownOptions = [];

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            pool_id: this.pool_id,
            usage: 'create_withdrawal',
            status: 'completed'
        };

        this.httpService.getInvestments(body).subscribe(res => {
            this.investments = res.data;

            if (this.investments.length > 0) {
                this.investments.forEach((element: any) => {
                    this.investmentDropdownOptions.push({
                        label: `${element.amount.toFixed(2)} ${element.currency.toUpperCase()} (${new Date(element.created).toDateString()})`,
                        value: element.lending_id,
                        optionEnabled: true
                    });
                });
    
                this.lending_id = res.data[0].lending_id;
                this.investment_info = res.data[0];
                
                this.calculateAmount();
    
                this.currencyDropdownOptions.push({
                    label: res.data[0].currency === 'usdt' ? 'Tether USD' : 'Circle USD',
                    value: res.data[0].currency,
                    logo: this.httpService.getCurrencyUrl(res.data[0].currency)
                });
            }

            else
                this.show_no_investments = true;

            this.hideLoader();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.show_no_investments = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    amountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.amount = parseFloat(amount.toString());
        this.fee = amount * (this.lender_fee_percentage / 100);
        this.received_amount = this.amount - this.fee;

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeInvestment(event: any) {
        this.lending_id = event.value;
        this.investment_info = this.investments.find((item: any) => {
            return item.lending_id === this.lending_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.investment_info.currency;

        let pending_interest = this.calculatePendingInterest(this.investment_info.interest_claimed_time, this.investment_info.outstanding_amount, this.investment_info.interest_rate);

        console.log('pending_interest initial : ', pending_interest);

        if (pending_interest < Math.pow(10, -2)) 
            pending_interest = 0;

        console.log('pending_interest final : ', pending_interest);

        this.cred_points = parseFloat((this.calculatePendingInterest(this.investment_info.interest_claimed_time, this.investment_info.outstanding_amount, this.investment_info.cred_percentage)).toFixed(6));

        console.log('calculateAmount : cred_points => ', this.cred_points);

        this.amountChanged(pending_interest);
    }

    calculatePendingInterest(interest_claimed_time: number, outstanding_principal_amount: number, interest_rate: number) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;
        
        const interest_per_min = outstanding_principal_amount * interest_rate / 100 / 365 / 24 / 60;
        
        const interest = interest_per_min * difference_in_minutes;
    
        return interest;
    }

    claimInterest() {
        const body = {
            lending_id: this.lending_id,
            vault_id: ''
        };
   
        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.btn_loading = true;

        this.httpService.claimInterest(body).subscribe((res: any) => {
            this.btn_loading = false;
            this.interest_id = res.data.interest_id;
            const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
    
            this.sendTransaction(transaction);
        }, (err: HttpErrorResponse) => {
            console.error('claimInterest error');
            console.error(err);
        
            this.cfAlertService.showError(err);
        
            this.btn_loading = false;
        });
    }

    updateInterestSignature(signature: string, error_code: number) {
        const body = {
            interest_id: this.interest_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateInterestWithdrawal(body).subscribe((res: any) => {
            this.btn_loading = false;
    
            this.cfAlertService.showMessage('Earnings claimed');
    
            this.router.navigateByUrl('/transactions?tab=my-earnings');
        }, (err: HttpErrorResponse) => {
            console.error('updateInterestWithdrawal error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async sendTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(networks[this.httpService.network].rpc, 'confirmed');

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('sendTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split('custom program error:');

            if (split_items.length > 0) 
                error_code = parseInt(split_items[split_items.length - 1].trim(), 16);

            console.log('sendTransaction : error_code => ', error_code);
        }

        finally {
            this.updateInterestSignature(txnSignature, error_code);
        }
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    changePool(event: any) {
        this.pool_id = event.value;
        const pool = this.pools.find((pool: any) => pool.pool_id === this.pool_id);

        if (pool) {
            this.kyc_required = pool.kyc_required;
            console.log(this.kyc_required);
        }
        
        this.getInvestments();
    }

    getVaults() {
        const data = {
            pool_id: this.pool_id
        };

        this.httpService.getVaults(data).subscribe(res => {
            res.data.forEach((x: any) => {
                this.vault_dropdown_options.push({
                    label: x.name,
                    value: x.pool_id,
                    description: x.name,
                    logo: x.image_url,
                    optionEnabled: true
                });
            });

            if (this.vault_dropdown_options.length > 0) {
                this.selected_vault = this.vault_dropdown_options[0];
                this.selected_vault_id = this.selected_vault.value;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeVault(event: any) {
        console.log('changeVault => ', event.value);

        this.selected_vault_id = event.value;
    }
}
