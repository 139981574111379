<div class="app-register-steps">
    <div class="row gx-0">
        <div class="col-md-12">
            <span class="heading">Welcome to Credible</span>
        </div>
    </div>
    <div class="sidebar-and-steps">
        <div class="steps-web">
            <div class="steps-heading">
                <div *ngFor="let step of steps; let i = index" class="step" [ngClass]="{
                        'step-active': i === currentStep,
                        'step-complete': step.completed
                    }" (click)="changeStep(i)">
                    <div class="step-counter">
                        <span *ngIf="!step.completed">{{ i + 1 }}</span>
                        <i class="fa-solid fa-check" *ngIf="step.completed"></i>
                    </div>
                    <div class="step-title">{{ step.title }}</div>
                    <div class="step-divider">
                        <hr />
                    </div>
                </div>
            </div>

            <div class="steps-content">
                <div *ngIf="steps[currentStep]?.step === 'connect-wallet'" class="step-content">
                    <app-connect-wallet (connected)="notifyWalletConnected($event)"
                        (next)="connectWalletNext()"></app-connect-wallet>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'user-profile'" class="step-content">
                    <app-user-profile (completed)="notifyProfileUpdated($event)"
                        (previous)="previousStep()"></app-user-profile>
                </div>
                <div *ngIf="steps[currentStep]?.step === 'connect-socials'" class="step-content">
                    <app-connect-socials (completed)="notifySocialsConnected($event)"
                        (previous)="previousStep()"></app-connect-socials>
                </div>

                <div *ngIf="steps[currentStep]?.step === 'invite-code'" class="step-content">
                    <app-invite-code (completed)="notifyInviteCode($event)"
                        (previous)="previousStep()"></app-invite-code>
                </div>

                <div *ngIf="steps[currentStep]?.step === 'disclosure'" class="step-content">
                    <app-disclosure (completed)="notifyTermsAndCondittions($event)"
                        (previous)="previousStep()"></app-disclosure>
                </div>
            </div>
        </div>

        <div class="steps-mobile">
            <div class="step-container" *ngFor="let step of steps; let i = index">
                <div class="step-heading">
                    <div class="step" [ngClass]="{
                                    'step-active': i === currentStep,
                                    'step-complete': step.completed
                                }" (click)="changeStep(i)">
                        <div class="step-counter">
                            <span *ngIf="!step.completed">{{ i + 1 }}</span>
                            <i class="fa-solid fa-check" *ngIf="step.completed"></i>
                        </div>
                        <div class="step-title">{{ step.title }}</div>
                    </div>
                </div>

                <div class="step-content">
                    <app-connect-wallet (connected)="notifyWalletConnected($event)"
                        *ngIf="currentStep === i && steps[currentStep]?.step === 'connect-wallet'"></app-connect-wallet>

                    <app-user-profile (completed)="notifyProfileUpdated($event)"
                        *ngIf="currentStep === i && steps[currentStep]?.step === 'user-profile'"></app-user-profile>

                    <app-connect-socials (completed)="notifySocialsConnected($event)"
                        *ngIf="currentStep === i && steps[currentStep]?.step === 'connect-socials'"></app-connect-socials>

                    <app-invite-code *ngIf="currentStep === i && steps[currentStep]?.step === 'invite-code'"
                        (completed)="notifyInviteCode($event)"></app-invite-code>

                    <app-disclosure *ngIf="currentStep === i && steps[currentStep]?.step === 'disclosure'"
                        (completed)="notifyTermsAndCondittions($event)"></app-disclosure>
                </div>
            </div>
        </div>
    </div>
</div>