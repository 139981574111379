import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import WalletEvent from '../models/WalletEvent';
import {
    Transaction, PublicKey, TransactionInstruction, 
    Connection
} from '@solana/web3.js';
import { networks } from '../app/app.config';
import { SolanaNetwork } from '../enums/SolanaNetwork';

@Injectable({
    providedIn: 'root'
})
export class WalletService {
    event = new BehaviorSubject(new WalletEvent());
    current_provider: string = '';
    ledger: boolean = false;
    messageForSigning: string = 'Sign below to authenticate with Credible finance';

    constructor() { }

    setEvent(provider: string, name: string, metadata: any) {
        this.event.next({
            name,
            provider,
            metadata
        });
    }

    getEvent() {
        return this.event.asObservable();
    }

    buildAuthTx(nonce: string): Transaction {
        const MEMO_PROGRAM_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr');

        const tx = new Transaction();
        const transactionInstruction = new TransactionInstruction({
            programId: MEMO_PROGRAM_ID,
            keys: [],
            data: Buffer.from(nonce, 'utf8')
        });

        tx.add(transactionInstruction);

        return tx;
    }

    validateAuthTx = (tx: Transaction | undefined, nonce: string): boolean => {
        console.log('validateAuthTx start', tx, nonce);

        if (!tx)
            return false;

        const MEMO_PROGRAM_ID = new PublicKey('MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr');

        try {
            const inx = tx.instructions[2];

            for (let i = 0; i < tx.instructions.length; i++)
                console.log('inx.programId', i, tx.instructions[i].programId.toBase58());

            console.log('inx', inx);

            console.log('inx.programId', inx.programId.toBase58());
            console.log('inx.programId.equals(MEMO_PROGRAM_ID)', inx.programId.equals(MEMO_PROGRAM_ID));

            if (!inx.programId.equals(MEMO_PROGRAM_ID))
                return false;

            console.log('inx.data.toString() !== nonce', inx.data.toString() !== nonce);

            if (inx.data.toString() !== nonce)
                return false;

            console.log('tx.verifySignatures()', tx.verifySignatures());

            if (!tx.verifySignatures())
                return false;
        }
        catch (e) {
            console.error(e);

            return false;
        }

        return true;
    };

    async getLatestBlockhash() {
        const connection = new Connection(networks[SolanaNetwork.MainnetBeta].rpc, 'confirmed');

        return (await connection.getLatestBlockhash()).blockhash;
    }
}
