<div class="app-homepage">
    <div class="section-1">
        <div class="background">
            <div class="top-section">
                <div class="heading">
                    <div class="sub-title">
                        <!-- <span>Tokenization powered Fintech Innovation</span> -->
                        <span>On-chain liquidity, Off-chain Payments</span>
                    </div>
                    <div class="title">
                        <!-- <span>First Decentralized Marketplace</span>
                        <br />
                        <span>for Private Equity & Credit.</span> -->
                        <span>Global Payments Financing</span>
                        <br />
                        <span>for Businesses & Aliens.</span>
                    </div>
                </div>
            </div>

            <div class="bottom-section">
                <div class="tvl-box">
                    <div class="tvl">
                        <span class="tvl-heading">Liquidity Supplied</span>
                        <span class="tvl-value">
                            ${{ stats.tvl_usd | number : "0.0-2" }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-2">
        <div class="pools-container">
            <div *ngIf="!loading">
                <div class="pools-list-container" *ngIf="fixed_income_pools.length > 0">
                    <div class="table-banner">
                        <div class="table-name-description">
                            <div class="table-sub-heading">NBFIs, Bonds, T-Bills</div>
                            <div class="table-heading">Private Credit</div>
                        </div>
                        <div class="table-controls" *ngIf="device === 'web'">
                            <cf-button label="View Pools" type="theme" (click)="redirectToInternalUrl('/liquidity')"
                                *ngIf="fixed_income_pools.length > 0"></cf-button>
                        </div>
                    </div>

                    <table>
                        <thead>
                            <th>Company</th>
                            <th *ngIf="device === 'web'">Type</th>
                            <th *ngIf="device === 'web'">TVL</th>
                            <th>APY</th>
                            <th *ngIf="device === 'web'">Rating</th>
                            <!-- <th *ngIf="device === 'web'">Verifier</th> -->
                            <th *ngIf="device === 'web'">Tenor</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pool of fixed_income_pools" (click)="selectPool(pool)"
                                [matTooltip]="pool.coming_soon ? 'Coming Soon' : ''"
                                [ngClass]="{ 'pool-disabled': pool.coming_soon }">
                                <td class="pool-name-container">
                                    <img [src]="pool.image_url" width="22" [alt]="pool.name" />
                                    {{ pool.name }}
                                </td>
                                <td *ngIf="device === 'web'">{{ pool.type }}</td>
                                <td *ngIf="device === 'web'">
                                    <span class="dollar-sign">$</span>{{ pool.stats.tvl_usd | number:'0.0-0' }}
                                </td>
                                <td>{{
                                    pool.lending_yield_rates[pool.lending_yield_rates.length - 1].apy
                                    }}<span class="percentage-sign">%</span>
                                </td>
                                <td *ngIf="device === 'web'">
                                    <span *ngIf="pool.borrower_overview.rating_agency_equivalent">
                                        {{ pool.borrower_overview.rating_agency_equivalent }}
                                    </span>
                                    <span *ngIf="!pool.borrower_overview.rating_agency_equivalent">
                                        Unrated
                                    </span>
                                </td>
                                <td *ngIf="device === 'web'">
                                    <span *ngIf="pool.tenor === 0">Flexible</span>
                                    <span *ngIf="pool.tenor > 0">{{ pool.tenor }} days</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="device === 'mobile'">
                            <tr>
                                <td colspan="2">
                                    <cf-button label="View Pools" type="theme"
                                        (click)="redirectToInternalUrl('/liquidity')"
                                        *ngIf="fixed_income_pools.length > 0"></cf-button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>

                <!-- <div class="pools-list-container" *ngIf="private_equity_pools.length > 0">
                    <div class="table-banner">
                        <div class="table-name-description">
                            <div class="table-sub-heading">Series A and Later Stage Companies</div>
                            <div class="table-heading">Private Equity</div>
                        </div>
                        <div class="table-controls" *ngIf="device === 'web'">
                            <cf-button label="View Pools" type="theme" (click)="redirectToInternalUrl('/liquidity')"
                                *ngIf="private_equity_pools.length > 0"></cf-button>
                        </div>
                    </div>

                    <table>
                        <thead>
                            <th>Company</th>
                            <th *ngIf="device === 'web'">Investors</th>
                            <th *ngIf="device === 'web'">Funds raised</th>
                            <th *ngIf="device === 'web'">Round series</th>
                            <th>Progress</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pool of private_equity_pools"
                                [matTooltip]="pool.coming_soon ? 'Coming Soon' : ''"
                                [ngClass]="{ 'pool-disabled': pool.coming_soon }">
                                <td class="pool-name-container">
                                    <img [src]="pool.image_url" width="22" [alt]="pool.name" />
                                    {{ pool.name }}
                                </td>
                                <td *ngIf="device === 'web'">{{ pool.existing_investors }}</td>
                                <td *ngIf="device === 'web'">{{ pool.raised }}</td>
                                <td *ngIf="device === 'web'">{{ pool.series }}</td>
                                <td>
                                    <div class="progress-container">
                                        <cf-circular-progress-bar [showAnimation]="false"
                                            [progress]="pool.progress"></cf-circular-progress-bar>
                                        <span>{{ pool.progress | number:'0.0-1' }}<span
                                                class="percentage-sign">%</span></span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot *ngIf="device === 'mobile'">
                            <tr>
                                <td colspan="2">
                                    <cf-button label="View Pools" type="theme"
                                        (click)="redirectToInternalUrl('/liquidity')"
                                        *ngIf="private_equity_pools.length > 0"></cf-button>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div> -->

                <div class="other-pools">
                    <div class="pools-list-container">
                        <div class="table-banner">
                            <div class="table-name-description">
                                <div class="table-sub-heading">Governance Token</div>
                                <div class="table-heading">CRED</div>
                            </div>
                            <div class="table-controls" *ngIf="device === 'web'">
                                <cf-button label="Buy $CRED" type="theme"
                                    (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/usdcred')"
                                    *ngIf="fixed_income_pools.length > 0"></cf-button>
                            </div>
                        </div>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Private Sale
                                    </td>
                                    <td>15<span class="percentage-sign">%</span></td>
                                </tr>
                                <tr>
                                    <td>
                                        Total Supply
                                    </td>
                                    <td>10,000,000,000</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="device === 'mobile'">
                                <tr>
                                    <td colspan="2">
                                        <cf-button label="Buy $CRED" type="theme"
                                            (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/usdcred')"
                                            *ngIf="fixed_income_pools.length > 0"></cf-button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>

                    <div class="pools-list-container">
                        <div class="table-banner">
                            <div class="table-name-description">
                                <div class="table-sub-heading">Trade Tokenized Assets</div>
                                <div class="table-heading">SWAP</div>
                            </div>
                            <div class="table-controls" *ngIf="device === 'web'">
                                <cf-button label="Swap" type="theme"
                                    (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/swap')"
                                    *ngIf="fixed_income_pools.length > 0"></cf-button>
                            </div>
                        </div>

                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        Assets
                                    </td>
                                    <td>$CRED, Private Equities</td>
                                </tr>
                                <tr>
                                    <td>
                                        Trade Liquidity
                                    </td>
                                    <td><span class="dollar-sign">$</span>XXXXX</td>
                                </tr>
                            </tbody>
                            <tfoot *ngIf="device === 'mobile'">
                                <tr>
                                    <td colspan="2">
                                        <cf-button label="Swap" type="theme"
                                            (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/swap')"
                                            *ngIf="fixed_income_pools.length > 0"></cf-button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-3">
        <span class="heading">Private Equities & Credit Opportunities from the leading companies</span>
        <app-marquee-carousel [per_cycle_duration]="marquee_per_cycle_duration" [duplication]="marquee_duplication"
            [arr]="marquee_items"></app-marquee-carousel>
    </div>

    <div class="section-4">
        <div class="our-solution">
            <div class="heading">Our Solution</div>

            <div class="content">
                <div class="left-section">
                    <div class="left-section-sub-heading">Earn Fixed Yield</div>
                    <div class="left-section-heading">Private Credit</div>
                    <div class="left-section-description">
                        Earn fixed yield from NBFIs, Debt funds, Bonds and partner RWA protocols.
                    </div>

                    <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Credit assessed & rated borrowers</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fintechs, NBFIs and real world applications as borrowers</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Short term opportunities, max 3-months lockup</span>
                        </div>
                    </div>

                    <div class="controls">
                        <cf-button label="Learn more" type="theme"
                            (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/private-credit')"></cf-button>
                    </div>
                </div>
                <div class="right-section">
                    <img src="https://assets.credible.finance/PrivateCredit2.png" alt="Private credit"
                        title="Private credit" loading="lazy" />
                </div>
            </div>
        </div>

        <!-- <div class="private-equity">
            <div class="content">
                <div class="left-section">
                    <img src="https://assets.credible.finance/private+equity-3.png" alt="Private Equity"
                        title="Private Equity" loading="lazy" />
                </div>

                <div class="right-section">
                    <div class="right-section-sub-heading">Participate in Series A & later stage equities</div>
                    <div class="right-section-heading">Private Equity</div>
                    <div class="right-section-description">
                        Tokenized shares of growth stage private equity curated from companies with a minimum $100
                        Million valuation.
                    </div>

                    <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Proven companies</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Existing reputed backers & investors</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Bonding pool mechanism to execute equity purchase</span>
                        </div>
                    </div>

                    <div class="controls">
                        <cf-button label="Learn more" type="theme"
                            (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/private-equity')"></cf-button>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="borrow">
            <div class="content">
                <div class="left-section">
                    <div class="left-section-sub-heading">Fintech</div>
                    <div class="left-section-heading">Borrow</div>
                    <div class="left-section-description">
                        Micro financing for SME trade finance and Healthcare consumer loans.
                    </div>

                    <div class="bullet-points">
                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Licensed NBFIs & VASPs</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fiat loans for healthcare & trade finance</span>
                        </div>

                        <div class="bullet-point">
                            <i class="fa-regular fa-circle-check"></i>
                            <span>Fiat loans for crypto collateral</span>
                        </div>
                    </div>

                    <div class="controls">
                        <cf-button label="Learn more" type="theme"
                            (click)="redirectToInternalUrl('https://credible.gitbook.io/docs/how-it-works/fintech')"></cf-button>
                    </div>
                </div>
                <div class="right-section">
                    <img src="https://assets.credible.finance/image4932.png" alt="Fintech" title="Fintech"
                        loading="lazy" />
                </div>
            </div>
        </div>
    </div>

    <div class="section-5">
        <div class="heading">Secure & Transparent</div>

        <div class="content">
            <div class="protocol-security-card">
                <div class="heading">Protocol Security</div>
                <div class="description">All our smart contracts are audited by Sec3. All are institutional borroweres
                    are rated by Credora</div>
                <div class="image">
                    <div class="image-holder">
                        <img src="https://assets.credible.finance/Credoralogo.png" alt="Credora" title="Credora"
                            loading="lazy" />
                        <span class="image-label">Credora rated</span>
                    </div>
                    <div class="image-holder">
                        <img src="https://assets.credible.finance/Sec3.png" alt="Sec3" title="Sec3" loading="lazy" />
                        <span class="image-label">Sec3 Audited</span>
                    </div>
                </div>
            </div>

            <div class="transactions-card">
                <div class="heading">Transparent</div>
                <div class="description">All transactions are on-chain publicly tracked</div>
                <div class="transactions">
                    <div class="transaction" *ngFor="let transaction of transactions"
                        (click)="httpService.redirectionTx(transaction.blockchain_txid)">
                        <div class="transaction-line-1">
                            <div class="transaction-amount">+{{ transaction.amount | number : "0.0-2" }} {{
                                transaction.currency | uppercase }}</div>
                            <div class="transaction-txid">{{ transaction.blockchain_txid }}</div>
                        </div>
                        <div class="transaction-line-2">
                            <div class="transaction-usd-value">
                                <span class="dollar-sign">$</span>
                                {{ transaction.amount_usd | number:'0.0-2' }}
                            </div>
                            <div class="transaction-date">{{ transaction.created | date : 'mediumDate' }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="section-6">
        <div class="heading">Ecosystem</div>
        <div class="description">A fintech powered by DeFi</div>

        <div class="ecosystem-image">
            <img src="https://assets.credible.finance/EcosystemImage.png" alt="Ecosystem" title="Ecosystem"
                loading="lazy" />
        </div>
    </div>

    <div class="section-7">
        <div class="our-solution">
            <div class="content">
                <div class="left-section">
                    <div class="left-section-sub-heading">Fintech</div>
                    <div class="left-section-heading">Consumer & SME Finance</div>
                    <div class="left-section-description">
                        In partnership with NBFIs in India and UAE, we are offering healthcare consumer loans and SME
                        trade finance loans.
                    </div>

                    <div class="controls">
                        <cf-button label="Coming soon" type="theme"></cf-button>
                    </div>

                    <!-- <div class="animated-image">
                        <img src="https://assets.credible.finance/Image55.png" alt="Apply Now" title="Apply Now"
                            loading="lazy" />
                    </div> -->

                    <div class="mobile-apps">
                        <img src="https://assets.credible.finance/image31.png" alt="Play store" title="Play store"
                            loading="lazy" />

                        <img src="https://assets.credible.finance/image41.png" alt="App store" title="App store"
                            loading="lazy" />
                    </div>
                </div>
                <div class="right-section">
                    <img src="https://assets.credible.finance/FintechImage.png" alt="Mobile app" title="Mobile app"
                        loading="lazy" />
                </div>
            </div>
        </div>
    </div>

    <div class="section-8">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div class="logo" [routerLink]="'/'">
                    <img src="https://assets.credible.finance/CredibleWhitelogo1.png" alt="Credible Finance"
                        title="Credible Finance" loading="lazy" (click)="scrollUp()" />
                </div>

                <div class="community">
                    <a href="https://twitter.com/crediblefin" target="_blank">
                        <i class="fa-brands fa-twitter"></i>
                    </a>
                    <a href="https://t.me/crediblefinance" target="_blank">
                        <i class="fa-brands fa-telegram"></i>
                    </a>
                    <a href="https://medium.com/@credible_finance" target="_blank">
                        <i class="fa-brands fa-medium"></i>
                    </a>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                <div class="row">
                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Product</span>
                            <span class="links-label" routerLink="/liquidity">Private Credit</span>
                            <span class="links-label" routerLink="/liquidity">Private Equity</span>
                            <span class="links-label" routerLink="/liquidity">Borrow</span>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Community</span>
                            <a href="https://twitter.com/crediblefin" target="_blank" class="links-label">Twitter</a>
                            <a href="https://t.me/crediblefinance" target="_blank" class="links-label">Telegram</a>
                            <a href="https://medium.com/@credible_finance" target="_blank"
                                class="links-label">Medium</a>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Resources</span>
                            <a href="https://credible.gitbook.io/" target="_blank" class="links-label">Whitepaper</a>
                            <a href="https://docsend.com/view/c4nm6fpy6kfk2vez" target="_blank"
                                class="links-label">Litepaper</a>
                            <span class="links-label">Developer Docs</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="d-grid">
                            <span class="links-heading">Legal</span>
                            <a (click)="redirectToUrl('/privacy-policy')" target="_blank" class="links-label">Privacy
                                Policy</a>
                            <a (click)="redirectToUrl('/terms-condition')" target="_blank" class="links-label">Terms
                                And Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>