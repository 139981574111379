<div class="kyc-container">
    <div class="kyc-container-inner">
        <div class="mt-3">
            <cf-button
                (click)="loadKYCWidget()"
                type="large"
                type="solid"
                [label]="'Complete your KYC'"
            ></cf-button>
        </div>
    </div>
</div>
