<div class="app-ambassador-tvl">
    <div class="top-section">
        <div class="section-heading">
            Liquidity Added
        </div>
        <div class="level-dropdown" matTooltip="Referral Level">
            <cf-dropdown [options]="levelOptions" [showLogo]="false" [showLabel]="false" (change)="levelChange($event)"
                [selected]="current_level"></cf-dropdown>
        </div>
    </div>

    <div class="middle-section">
        <cf-loader *ngIf="loading_stats"></cf-loader>
        <div class="member-stats" *ngIf="!loading_stats">
            <div>
                <cf-card [stat]="'$' + (stats?.all | number:'0.0-0')" heading="All time" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [stat]="'$' + (stats?.last_7d | number:'0.0-0')" heading="Last 7 days" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [stat]="'$' + (stats?.last_30d | number:'0.0-0')" heading="Last 30 days" type="compact"></cf-card>
            </div>
            <div>
                <cf-card [stat]="'$' + (stats?.last_60d | number:'0.0-0')" heading="Last 60 days" type="compact"></cf-card>
            </div>
        </div>
    </div>

    <div class="section-heading">
        TVL vs Date
    </div>

    <div class="bottom-section" [ngClass]="{'loader-container': loading_charts}">
        <cf-loader *ngIf="loading_charts" class="loader"></cf-loader>
        <div class="member-controls">
            <div class="member-duration-controls">
                <div class="member-control" matTooltip="12 hrs"
                    [ngClass]="{'active-control': current_chart_duration === '12h'}"
                    (click)="changeTvlChartDuration('12h')">12H</div>
                <div class="member-control" matTooltip="1 day"
                    [ngClass]="{'active-control': current_chart_duration === '1d'}"
                    (click)="changeTvlChartDuration('1d')">1D</div>
                <div class="member-control" matTooltip="3 day"
                    [ngClass]="{'active-control': current_chart_duration === '3d'}"
                    (click)="changeTvlChartDuration('3d')">3D</div>
                <div class="member-control" matTooltip="1 week"
                    [ngClass]="{'active-control': current_chart_duration === '1w'}"
                    (click)="changeTvlChartDuration('1w')">1W</div>
            </div>
            <div class="member-type-controls">
                <div class="member-control" matTooltip="cumulative"
                    [ngClass]="{'active-control': current_chart_type === 'cumulative'}"
                    (click)="changeTvlChartType('cumulative')">Cu</div>
                <div class="member-control" matTooltip="Non cumulative"
                    [ngClass]="{'active-control': current_chart_type === 'non_cumulative'}"
                    (click)="changeTvlChartType('non_cumulative')">NC</div>
            </div>
        </div>
        <div class="members-chart" *ngIf="!loading_charts">
            <app-line-chart [dataOne]="chart_data" title="" [height]="350"></app-line-chart>
        </div>
    </div>
</div>