import {
    Component, EventEmitter, HostListener, OnInit,
    Output
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { getWindow } from 'ssr-window';
import { HttpService } from '../../../services/http.service';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { OnboardingService } from '../../onboarding.service';

@Component({
    selector: 'app-connect-socials',
    templateUrl: './connect-socials.component.html',
    styleUrl: './connect-socials.component.scss'
})
export class ConnectSocialsComponent implements OnInit {
    window: Window;
    theme: any;
    width: any;

    twitterJoined: boolean = false;
    telegramJoined: boolean = false;

    @Output() completed = new EventEmitter<any>();
    @Output() previous = new EventEmitter<any>();

    telegram_join_loading: boolean = false;
    twitter_join_loading: boolean = false;
    currentUser: any;
    constructor(
        private router: Router,
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private onboardingService: OnboardingService
    ) {
        this.window = getWindow();
        this.width = this.window.outerWidth;
    }

    ngOnInit(): void {
        this.getCurrentTheme();
        this.getCurrentUser();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.outerWidth;

        console.log('width on resize', this.width);
    }

    getCurrentUser() {
        this.currentUser = this.httpService.user;
        console.log('currentUser', this.currentUser);

        if (this.currentUser && this.currentUser?.telegram?.group_joined)
            this.telegramJoined = true;

        if (this.currentUser && this.currentUser?.twitter?.group_joined)
            this.twitterJoined = true;

        console.log('this.telegramJoined ', this.telegramJoined);
        console.log('this.twitterJoined ', this.twitterJoined);

        if (this.telegramJoined && this.twitterJoined)
            this.next();
    }
    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    randomInteger(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    connectTelegram() {
        if (this.width > 768)
            this.window.open('https://t.me/crediblefinance', '_blank');

        this.telegram_join_loading = true;

        const body = {
            social_media_type: 'telegram'
        };

        this.apiCall(body);
    }

    connectTwitter() {
        if (this.width > 768)
            this.window.open('https://x.com/intent/user?screen_name=crediblefin', '_blank');

        this.twitter_join_loading = true;

        const body = {
            social_media_type: 'twitter'
        };

        this.apiCall(body);
    }

    apiCall(body: any) {
        this.onboardingService.updateSocialMedia(body).subscribe((res: any) => {
            if (body.social_media_type === 'telegram') {
                this.telegram_join_loading = false;
                this.telegramJoined = true;

                this.httpService.user.telegram = {
                    group_joined: true,
                    first_name: '',
                    last_name: '',
                    username: '',
                    id: 0
                };
            }

            if (body.social_media_type === 'twitter') {
                this.twitter_join_loading = false;
                this.twitterJoined = true;

                this.httpService.user.twitter = {
                    group_joined: true,
                    user_id: 0,
                    screen_name: ''
                };
            }

            this.cfAlertService.showMessage(res.message);

            if (this.twitterJoined && this.telegramJoined)
                this.next();
        }, (err: HttpErrorResponse) => {
            console.error('refferal code update error');
            console.error(err);

            this.cfAlertService.showError(err);

            if (body.social_media_type === 'telegram')
                this.telegram_join_loading = false;

            if (body.social_media_type === 'twitter')
                this.twitter_join_loading = false;
        });
    }

    previousHandler() {
        this.previous.emit();
    }

    next() {
        this.completed.emit();
    }
}
