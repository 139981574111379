import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import {
    Connection, Transaction 
} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import {Router} from '@angular/router';
import IDrawdown from '../../interfaces/IDrawdown';
import { networks } from '../app.config';
import { nullChecker } from '../helpers/nullChecker';

@Component({
    selector: 'app-new-repayment',
    templateUrl: './new-repayment.component.html',
    styleUrls: [ './new-repayment.component.scss' ]

})

export class NewRepaymentComponent implements OnInit {
    currencyDropdownOptions: Array<IDropdown> = [];
    amount: number = 0;
    amount_usd: number = 0;
    conversion_rate: number = 0;
    currency: string = 'usdc';

    drawdownDropdownOptions: Array<IDropdown> = [];
    drawdowns: Array<IDrawdown> = [];

    repayment_id: string = '';

    loading: boolean = false;
    btn_loading: boolean = false;

    drawdown_info: any = {};
    show_no_drawdowns: boolean = false;

    theme: any;

    pool_id: string = '';

    @Input() drawdown_id: string = '';

    constructor(
        
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private walletService: WalletService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getCurrentTheme();

        if (this.httpService.user.wallet_address !== '') 
            this.getDrawdowns();
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    getDrawdowns() {
        this.showLoader();

        const body = {
            page: 0,
            limit: 1000,
            token_required: true,
            usage: 'create_repayment'
        };

        this.httpService.getDrawdowns(body).subscribe(res => {
            this.drawdowns = res.data;

            if (this.drawdowns.length > 0) {
                this.drawdowns.forEach((element: any) => {
                    this.drawdownDropdownOptions.push({
                        label: `${element.amount.toFixed(2)} ${element.currency.toUpperCase()} (${new Date(element.created).toDateString()})`,
                        value: element.drawdown_id,
                        optionEnabled: true
                    });
                });

                if (nullChecker(this.drawdown_id)) {
                    this.drawdown_id = res.data[0].drawdown_id;

                    this.drawdown_info = res.data.find((item: any) => {
                        return item.drawdown_id === this.drawdown_id;
                    });
                }

                else 
                    this.drawdown_info = res.data[0];
                
                this.calculateAmount();
    
                this.currencyDropdownOptions.push({
                    label: this.drawdown_info.currency === 'usdt' ? 'Tether USD' : 'Circle USD',
                    value: this.drawdown_info.currency,
                    logo: this.httpService.getCurrencyUrl(this.drawdown_info.currency)
                });

                this.pool_id = this.drawdown_info.pool_id;
            }

            else
                this.show_no_drawdowns = true;

            this.hideLoader();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.show_no_drawdowns = false;
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    amountChanged(amount: number) {
        if (!amount) 
            amount = 0;
        
        this.amount = parseFloat(amount.toString());

        console.log( this.currency);
        console.log( this.httpService.currencyConversionMap);

        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;
        this.amount_usd = this.amount * this.conversion_rate;
    }

    changeDrawdown(event: any) {
        this.drawdown_id = event.value;
        this.drawdown_info = this.drawdowns.find((item: any) => {
            return item.drawdown_id === this.drawdown_id;
        });

        this.calculateAmount();
    }

    calculateAmount() {
        this.currency = this.drawdown_info.currency;

        let pending_interest = this.calculatePendingInterest(this.drawdown_info.last_interest_payment_time, this.drawdown_info.outstanding_amount, this.drawdown_info.interest_rate);

        console.log('pending_interest initial : ', pending_interest);

        if (pending_interest < Math.pow(10, -2)) 
            pending_interest = 0;

        console.log('pending_interest final : ', pending_interest);
    
        this.amountChanged(pending_interest);
    }

    calculatePendingInterest(interest_claimed_time: number, outstanding_principal_amount: number, interest_rate: number) {
        const current_time = new Date().getTime();
        const difference_in_minutes = (current_time - interest_claimed_time) / 60000;
        
        const interest_per_min = outstanding_principal_amount * interest_rate / 100 / 365 / 24 / 60;
        
        const interest = interest_per_min * difference_in_minutes;
    
        return interest;
    }

    createRepayment() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        const body = {
            drawdown_id: this.drawdown_id,
            amount: this.amount,
            currency: this.currency.toLowerCase()
        };

        this.btn_loading = true;

        this.httpService.createRepayment(body).subscribe((res: any) => {    
            this.btn_loading = false;
            this.repayment_id = res.data.repayment_id;
            const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));

            this.sendTransaction(transaction);
        }, (err: HttpErrorResponse) => {
            console.error('Withdrawal error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.btn_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    updateSignature(signature: string) {
        const body = {
            drawdown_id: this.drawdown_id,
            repayment_id: this.repayment_id,
            blockchain_txid: signature
        };

        this.httpService.updateRepayment(body).subscribe((res: any) => {
            this.btn_loading = false;

            this.cfAlertService.showMessage('Repayment succesful');

            this.router.navigateByUrl('/transactions?tab=my-repayments');
        }, (err: HttpErrorResponse) => {
            console.error('updateRepayment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.btn_loading = false;
        });
    }

    async sendTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(networks[this.httpService.network].rpc, 'confirmed');

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }

        catch (err) {
            console.error('sendTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.btn_loading = false;
        }
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }
}
