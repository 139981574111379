import {
    ASSOCIATED_TOKEN_PROGRAM_ID, TOKEN_PROGRAM_ID, getAssociatedTokenAddress 
} from '@solana/spl-token';
import {
    Connection, PublicKey 
} from '@solana/web3.js';
import { networks } from '../app.config';

async function getWalletBalance(cluster: any, wallet_address: string, payment_currency: string, currentMintAddressMap: any): Promise<number> {
    if (wallet_address === '')
        return 0;

    if (payment_currency in currentMintAddressMap === false)
        return 0;

    try {
        console.log('payment_currency', payment_currency);

        const mint_address = new PublicKey(currentMintAddressMap[payment_currency]);

        const source = new PublicKey(wallet_address);

        const sourceATA = await getAssociatedTokenAddress(mint_address, source, false, TOKEN_PROGRAM_ID, ASSOCIATED_TOKEN_PROGRAM_ID);

        const connection = new Connection(networks[cluster].rpc);

        const info = await connection.getTokenAccountBalance(sourceATA);

        console.log(info);

        const balance = parseInt(info.value.amount) / Math.pow(10, info.value.decimals);

        return balance;
    } 
    catch (err) {
        // console.error(err);

        return 0;
    }
}

export default getWalletBalance;