<div class="app-new-withdrawal" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div *ngIf="
            !loading &&
            httpService.user.wallet_address !== '' &&
            !show_no_drawdowns
        ">
        <div class="row">
            <cf-dropdown [theme]="theme" [options]="drawdownDropdownOptions" (change)="changeDrawdown($event)"
                label="Select drawdown" [showLogo]="false" [selected]="drawdown_id" [showDescription]="true">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <cf-input-currency-dropdown [theme]="theme" [options]="currencyDropdownOptions" [value]="amount"
                [class]="'input'" label="Amount" [selected]="currency" type="number"
                (valueChanged)="amountChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
        </div>

        <div class="row mt-2">
            <div class="new-withdrawal-summary">
                <div class="summary-item">
                    <span class="summary-heading">Total outstanding drawdown</span>
                    <span class="summary-value">{{
                        drawdown_info.amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Outstanding principal amount</span>
                    <span class="summary-value">{{
                        drawdown_info.outstanding_amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">Repaid principal amount</span>
                    <span class="summary-value">{{
                        drawdown_info.repaid_amount
                        | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">You will now repay</span>
                    <span class="summary-value">{{
                        amount | number : httpService.getPrecision(currency)
                        }}
                        {{ currency | uppercase }}</span>
                </div>
            </div>
        </div>

        <div class="actions">
            <div class="action">
                <div *ngIf="!btn_loading">
                    <cf-button label="Repay" type="solid" [disabled]="httpService.user.wallet_address === ''"
                        (click)="createRepayment()" [loading]="btn_loading"></cf-button>
                </div>
                <div *ngIf="btn_loading">
                    <cf-loader></cf-loader>
                </div>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
    <div *ngIf="httpService.user.wallet_address === ''">
        <cf-button label="Connect wallet" type="outline" (click)="connectWallet()"></cf-button>
    </div>
    <div *ngIf="show_no_drawdowns" class="no_data">
        <div>No drawdowns</div>
    </div>
</div>