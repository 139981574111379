import {
    HttpClient, HttpHeaders 
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { serverUrl } from '../app/app.config';
import { LocalStorageService } from './localstorage.service';

@Injectable({
    providedIn: 'root'
})
export class SuperTeamService {
    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {

    }

    getAuthToken() {
        const token = this.localStorageService.getItem('token');

        return token ? token : '';
    }

    checkSuperTeamUser() {
        console.log('checkSuperTeamUser token : ', this.getAuthToken());

        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}checkSuperTeamUser`, {}, httpOptions);
    }

    checkSolanaTokenBalance(body: any) {
        const httpOptions = {
            headers: new HttpHeaders({
                token: this.getAuthToken()
            })
        };

        return this.http.post<any>(`${serverUrl}checkSolanaTokenBalance`, body, httpOptions);
    }
}
