import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, Input, OnInit
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import { DatePipe } from '@angular/common';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';

import IPool from '../../interfaces/IPool';

import { Router } from '@angular/router';

import { WalletService } from '../../services/wallet.service';
import {
    Connection, Transaction
} from '@solana/web3.js';
import { PhantomService } from '../../services/phantom.service';
import { SolflareService } from '../../services/solflare.service';
import getWalletBalance from '../helpers/getWalletBalance';
import Pool from '../../models/Pool';
import { nullChecker } from '../helpers/nullChecker';
import getWalletBalanceMainAddress from '../helpers/getWalletBalanceMainAddress';
import { networks } from '../app.config';

@Component({
    selector: 'app-new-investment',
    templateUrl: './new-investment.component.html',
    styleUrls: [ './new-investment.component.scss' ]

})
export class NewInvestmentComponent implements OnInit {
    selected_duration: number = 0;
    durationDropdownOptions: Array<IDropdown> = [];
    selectedDurationItem: any;
    expiry_date: string | undefined = '';
    currentMintAddressMap: any = {};
    paymentCurrencyDropdownOptions: Array<IDropdown> = [];
    payment_currency: string = 'usdc';
    payment_amount: number = 0;
    annual_interest: number = 0;
    annual_interest_rate: number = 0;
    cred_points: number = 0;
    cred_percentage: number = 0;
    interest: number = 0;
    schedule: Array<any> = [];
    pools: Array<IDropdown> = [];

    lending_id: string = '';
    pool: IPool = new Pool();
    balance: number = 0;
    sol_balanace: number = 0;
    current_summary_tab: string = 'investment-summary';

    loading: boolean = false;
    new_investment_loading: boolean = false;
    theme: any;
    transaction: any;
    lendOptions: Array<any> = [];
    user : any;

    vault_dropdown_options: Array<IDropdown> = [];
    selected_vault: any;
    selected_vault_id: string = '';
    additionalYieldLabel: string = 'CRED';

    @Input() pool_id: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private datePipe: DatePipe,
        private router: Router,
        private phantomService: PhantomService,
        private solflareService: SolflareService,
        private walletService: WalletService
    ) { }

    ngOnInit(): void {
        console.log('new-investment.component.ts ngOnInit()');
        
        this.getPaymentCurrencyDropdownOptions();
        this.getCurrentTheme();
        this.user = this.httpService.user;
        console.log(this.user);
        this.lendOptions = [{
            label: 'Liquidity',
            value: 'liquidity',
            optionEnabled: true
        }, {
            label: 'Withdraw',
            value: 'withdraw',
            optionEnabled: true
        }];
    }
  
    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    getPools() {
        this.showLoader();

        this.httpService.getPools(null).subscribe(res => {
            res.data.forEach((element: any) => {
                this.pools.push({
                    label: element.name,
                    value: element.pool_id,
                    logo: element.image_url,
                    optionEnabled: true
                    // description: element.kyc_required ? 'true' : 'false'
                });
            });

            console.log('initial pool_id', this.pool_id, this.pools.length);
            
            if (this.pool_id === '' && this.pools.length > 0)
                this.pool_id = res.data[0].pool_id;

            console.log('final pool_id', this.pool_id);

            if (this.pool_id === 'badx61jpjr')
                this.additionalYieldLabel = 'CETES';

            if (this.httpService.user.wallet_address !== '')
                this.getVaults();

            this.getPoolDetails();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    getPaymentCurrencyDropdownOptions() {
        const paymentCurrencyDropdownOptions: Array<IDropdown> = [];

        this.httpService.getLendingCurrencies().subscribe(res => {
            // this.currentMintAddressMap = res.data;

            res.data.forEach((element: any) => {
                this.currentMintAddressMap[element.currency_key] = element.mint_address;

                paymentCurrencyDropdownOptions.push({
                    label: element.currency_name,
                    value: element.currency_key,
                    logo: element.logo,
                    optionEnabled: true
                });
            });

            this.paymentCurrencyDropdownOptions = paymentCurrencyDropdownOptions;

            if (this.paymentCurrencyDropdownOptions.length > 0)
                this.payment_currency = this.paymentCurrencyDropdownOptions[0].value;

            this.getPools();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    getPoolDetails() {
        this.httpService.getPoolDetails(this.pool_id).subscribe(res => {
            this.pool = res.data;

            this.annual_interest_rate = this.pool.lending_yield_rates[0].currency_percentage;
            this.cred_percentage = !nullChecker(this.pool.lending_yield_rates[0].cred_percentage) ? this.pool.lending_yield_rates[0].cred_percentage : 0;

            const durationDropdownOptions: Array<IDropdown> = [];

            console.log('this.pool', this.pool);
            this.pool.lending_yield_rates.forEach((x) => {
                durationDropdownOptions.push({
                    label: x.days === 0 ? 'Flexible' : `${x.days} days`,
                    value: x.days.toString(),
                    description: `${this.getInterestRate(x.days)}% APY`,
                    optionEnabled: true
                });
            });

            this.durationDropdownOptions = durationDropdownOptions;

            this.selectedDurationItem = this.durationDropdownOptions[0];
            this.selected_duration = parseInt(this.durationDropdownOptions[0].value);

            console.log('INITIAL => ', this.selected_duration);

            this.payment_amount = this.pool.min_lending_amount_per_tx;

            this.pool_id = this.pool.pool_id;

            // this.httpService.setMetaData(`Invest in ${this.pool.name}`, `${this.pool.description}`);

            if (this.durationDropdownOptions.length > 0)
                this.changeDuration(this.durationDropdownOptions[0]);

            if (this.paymentCurrencyDropdownOptions.length > 0)
                this.paymentCurrencyChanged(this.paymentCurrencyDropdownOptions[0]);

            this.getWalletBalanceHelper();
            this.getWalletBalanceMainAddressHelper();

            this.hideLoader();
        }, (err: HttpErrorResponse) => {
            this.hideLoader();

            console.error(err);

            this.cfAlertService.showError(err);
            this.backToPools();
        });
    }

    async getWalletBalanceHelper() {
        this.balance = await getWalletBalance(this.httpService.network, this.httpService.user.wallet_address, this.payment_currency, this.currentMintAddressMap);
    }

    async getWalletBalanceMainAddressHelper() {
        this.sol_balanace = await getWalletBalanceMainAddress(this.httpService.network, this.httpService.user.wallet_address, 'sol');

        console.log('SOL BALANCE => ', this.sol_balanace);
    }
    
    paymentCurrencyChanged(option: IDropdown) {
        this.payment_currency = option.value;
        this.balance = 0;

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();

        this.getWalletBalanceHelper();
        this.getWalletBalanceMainAddressHelper();
    }

    paymentAmountChanged(payment_amount: number) {
        if (!payment_amount)
            payment_amount = 0;

        this.payment_amount = parseFloat(payment_amount.toString());

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();
    }

    getInterestRate(days: number) {
        const item = this.pool.lending_yield_rates.find(x => x.days === days);

        return item ? item.currency_percentage : 0;
    }

    getCredPercentage(days: number) {
        const item = this.pool.lending_yield_rates.find(x => x.days === days);

        return item ? item.cred_percentage : 0;
    }

    changeDuration(event: any) {
        console.log('changeDuration => ', event.value);

        this.selected_duration = parseInt(event.value);

        this.annual_interest_rate = this.getInterestRate(this.selected_duration);
        this.cred_percentage = this.getCredPercentage(this.selected_duration);

        this.expiry_date = this.datePipe.transform(new Date().getTime() + (this.selected_duration * 24 * 60 * 60 * 1000))?.toString();

        this.annual_interest = this.payment_amount * this.annual_interest_rate / 100;
        this.cred_points = Math.round(this.payment_amount * (this.cred_percentage / 100));

        this.interest = this.getTotalInterest();
    }

    getTotalInterest() {
        // flexible
        if (this.selected_duration === 0)
            return this.annual_interest;

        return this.annual_interest / 12 * this.selected_duration;
    }

    changePool(event: any) {
        this.pool_id = event.value;

        this.getPoolDetails();
    }

    createInvestment() {
        if (isNaN(this.payment_amount) || this.payment_amount === null || this.payment_amount === undefined || this.payment_amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        if (this.balance < this.payment_amount) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Insufficient balance'
                }
            }));

            return;
        }

        if (this.payment_amount < this.pool.min_lending_amount_per_tx) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: `Minimum investment amount is ${this.pool.min_lending_amount_per_tx} ${this.payment_currency.toUpperCase()}`
                }
            }));

            return;
        }

        if (this.sol_balanace <= 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Insufficient SOL balance'
                }
            }));

            return;
        }

        this.new_investment_loading = true;

        const body = {
            amount: this.payment_amount,
            currency: this.payment_currency,
            duration_days: this.selected_duration,
            pool_id: this.pool_id,
            vault_id: ''
        };

        if (!nullChecker(this.selected_vault_id))
            body.vault_id = this.selected_vault_id;

        this.httpService.createInvestment(body).subscribe((res: any) => {
            this.lending_id = res.data.lending_id;
            const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));
    
            this.sendTransaction(transaction);
        }, (err: HttpErrorResponse) => {
            console.error('createInvestment error');
            console.error(err);
    
            this.cfAlertService.showError(err);
    
            this.new_investment_loading = false;
        });
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        // else if (this.walletService.current_provider === 'solflare') {
        //     const response = await this.solflareService.signTransaction(transaction);

        //     return response;
        // }

        return undefined;
    }

    async sendTransaction(transaction: Transaction) {
        let error_code = -1;
        let txnSignature = '';

        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res', res);

            if (!res || !res.signature)
                return;
            
            transaction = res;

            const connection = new Connection(networks[this.httpService.network].rpc, 'confirmed');

            console.log('transaction', transaction);

            txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('sendTransaction : txn signature => ', txnSignature);
        }

        catch (err: any) {
            console.error('sendTransaction catch');
            console.error(err);

            const error_info = new HttpErrorResponse({
                error: err
            });

            const split_items = error_info.error.message.split('custom program error:');

            if (split_items.length > 0) 
                error_code = parseInt(split_items[split_items.length - 1].trim(), 16);

            console.log('sendTransaction : error_code => ', error_code);
        }

        finally {
            this.updateSignature(txnSignature, error_code);
        }
    }

    updateSignature(signature: string, error_code: number) {
        const body = {
            lending_id: this.lending_id,
            blockchain_txid: signature,
            error_code: error_code
        };

        this.httpService.updateInvestment(body).subscribe((res: any) => {
            this.new_investment_loading = false;

            this.cfAlertService.showMessage('Supply succesful');

            this.router.navigateByUrl('/transactions?tab=my-supply');
        }, (err: HttpErrorResponse) => {
            console.error('updateInvestment error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_investment_loading = false;
        });
    }

    backToPools() {
        this.router.navigate([ `/pools/${this.pool_id}` ]);
    }

    connectWallet() {
        this.router.navigate([ '/connect-wallet' ], {
            queryParams: {
                returnUrl: this.router.url
            }
        });
    }

    getVaults() {
        const data = {
            pool_id: this.pool_id
        };

        this.httpService.getVaults(data).subscribe(res => {
            res.data.forEach((x: any) => {
                this.vault_dropdown_options.push({
                    label: x.name,
                    value: x.pool_id,
                    description: x.name,
                    logo: x.image_url,
                    optionEnabled: true
                });
            });

            if (this.vault_dropdown_options.length > 0) {
                this.selected_vault = this.vault_dropdown_options[0];
                this.selected_vault_id = this.selected_vault.value;
            }
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    changeVault(event: any) {
        console.log('changeVault => ', event.value);

        this.selected_vault_id = event.value;
    }
}
