import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
    constructor(private httpService: HttpService) {
        // console.log('interceptor created');
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('interceptor', req.method, req.url);
        
        // Clone the request to add the new header
        const clonedRequest = req.clone({
            headers: req.headers.append('X-NETWORK', this.httpService.network)
        });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
    }
}