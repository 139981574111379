import {
    Component, HostListener, OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { HttpService } from '../../services/http.service';

import { getWindow } from 'ssr-window';
import { LineChartComponent } from '../charts/line-chart/line-chart.component';
import { nullChecker } from '../helpers/nullChecker';

@Component({
    selector: 'app-borrow-pool-details',
    templateUrl: './borrow-pool-details.component.html',
    styleUrl: './borrow-pool-details.component.scss'
})

export class BorrowPoolDetailsComponent implements OnInit {
    category_id: string | null = null;
    pool_id: string | null = null;

    window: Window;

    theme: any;
    width: any;

    currentTab: string = 'drawdown';
    drawdown_id: string = '';

    @ViewChild(LineChartComponent) child!: LineChartComponent;

    constructor(private route: ActivatedRoute, public httpService: HttpService) {
        this.window = getWindow();
    }

    @HostListener('window:resize', [ '$event' ])
    onResize(event: any) {
        this.width = event.target.innerWidth;
    }

    ngOnInit(): void {
        this.category_id = this.route.snapshot.paramMap.get('category_id');
        this.pool_id = this.route.snapshot.paramMap.get('pool_id');

        this.getCurrentTheme();

        this.width = this.window.innerWidth;

        this.selectCurrentTab();
    }

    selectCurrentTab() {
        const tabs = [{
            key: 'drawdown'
        }, {
            key: 'repay'
        }];

        this.route.queryParams.subscribe(params => {
            if (params['tab']) {
                const tab = params['tab'];

                if (tabs.find(t => t.key === tab))
                    this.currentTab = tab;
            }

            if (!nullChecker(params['drawdown_id'])) 
                this.drawdown_id = params['drawdown_id'];
        });
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }
}
