import { HttpErrorResponse } from '@angular/common/http';
import {
    Component, OnInit, Input
} from '@angular/core';
import { CfAlertService } from '@crediblefinance/credible-ui';
import { HttpService } from '../../services/http.service';
import IDropdown from '@crediblefinance/credible-ui/lib/interfaces/IDropdown';
import IPool from '../../interfaces/IPool';

import {
    Connection, Transaction
} from '@solana/web3.js';
import { Router } from '@angular/router';
import { PhantomService } from '../../services/phantom.service';
import { WalletService } from '../../services/wallet.service';
import { networks } from '../app.config';

@Component({
    selector: 'app-new-drawdown',
    templateUrl: './new-drawdown.component.html',
    styleUrl: './new-drawdown.component.scss'
})

export class NewDrawdownComponent implements OnInit {
    amount_usd: number = 0;
    currentMintAddressMap: any = {};
    currencyDropdownOptions: Array<IDropdown> = [];
    currency: string = 'usdc';

    amount: number = 0;
    fee: number = 0;
    received_amount: number = 0;
    borrower_fee_percentage: number = 0;
    min_borrow_amount: number = 0;

    conversion_rate: number = 0;

    loading: boolean = false;
    new_drawdown_loading: boolean = false;
    theme: any;
    transaction: any;

    drawdown_id: string = '';

    pools: Array<IPool> = [];
    poolDropdownOptions: Array<IDropdown> = [];

    @Input() pool_id: string = '';

    constructor(
        public httpService: HttpService,
        private cfAlertService: CfAlertService,
        private phantomService: PhantomService,
        private walletService: WalletService,
        private router: Router
    ) { }

    ngOnInit(): void {
        console.log('new-drawdown.component.ts ngOnInit()');
        
        this.getCurrencyDropdownOptions();

        this.getCurrentTheme();
    }

    getCurrentTheme() {
        this.httpService.getCurrentTheme().subscribe(res => {
            this.theme = res;
        });
    }

    getCurrencyDropdownOptions() {
        this.showLoader();

        this.httpService.getLendingCurrencies().subscribe(res => {
            this.currentMintAddressMap = res.data;

            const currencyDropdownItems: Array<IDropdown> = [];

            res.data.forEach((element: any) => {
                this.currentMintAddressMap[element.currency_key] = element.mint_address;

                currencyDropdownItems.push({
                    label: element.currency_name,
                    value: element.currency_key,
                    logo: element.logo,
                    optionEnabled: true
                });
            });

            this.currencyDropdownOptions = currencyDropdownItems;

            if (this.currencyDropdownOptions.length > 0)
                this.currency = this.currencyDropdownOptions[0].value;

            this.getPools();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.cfAlertService.showError(err);
        });
    }

    showLoader() {
        this.loading = true;
    }

    hideLoader() {
        this.loading = false;
    }

    currencyChanged(option: IDropdown) {
        this.currency = option.value;
        this.conversion_rate = this.httpService.currencyConversionMap[this.currency].usd;

        this.amount_usd = this.amount * this.conversion_rate;
    }

    amountChanged(amount: number) {
        if (!amount)
            amount = 0;

        this.amount = parseFloat(amount.toString());
        this.fee = amount * (this.borrower_fee_percentage / 100);
        this.received_amount = this.amount - this.fee;

        this.amount_usd = this.amount * this.conversion_rate;
    }

    createDrawdown() {
        if (isNaN(this.amount) || this.amount === null || this.amount === undefined || this.amount === 0) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: 'Please enter valid amount'
                }
            }));

            return;
        }

        if (this.amount < this.min_borrow_amount) {
            this.cfAlertService.showError(new HttpErrorResponse({
                error: {
                    message: `Minimum borrow amount is ${this.min_borrow_amount.toFixed(2)} ${this.currency.toUpperCase()}`
                }
            }));

            return;
        }

        this.new_drawdown_loading = true;

        const body = {
            amount: this.amount,
            currency: this.currency,
            pool_id: this.pool_id
        };
  
        this.httpService.createDrawdown(body).subscribe((res: any) => {
            this.drawdown_id = res.data.drawdown_id;

            const transaction = Transaction.from(Buffer.from(res.data.transaction, 'base64'));

            this.sendTransaction(transaction);
        }, (err: HttpErrorResponse) => {
            console.error('createDrawdown error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    getPools() {
        this.httpService.getPools(null).subscribe(res => {
            this.pools = res.data;

            res.data.forEach((element: any) => {
                this.poolDropdownOptions.push({
                    label: element.name,
                    value: element.pool_id,
                    logo: element.image_url,
                    optionEnabled: true
                });
            });

            console.log('initial pool_id', this.pool_id, this.pools.length);
            
            if (this.pool_id === '' && this.pools.length > 0)
                this.pool_id = res.data[0].pool_id;

            console.log('final pool_id', this.pool_id);
  
            const pool_info = this.pools.find((item) => {
                return item.pool_id === this.pool_id;
            });

            if (pool_info) {
                this.borrower_fee_percentage = pool_info.borrower_fee_percentage;
                this.min_borrow_amount = pool_info.min_borrow_amount;
    
                this.fee = this.amount * (pool_info.borrower_fee_percentage / 100);
            }

            this.hideLoader();
        }, (err: HttpErrorResponse) => {
            console.error(err);

            this.hideLoader();

            this.cfAlertService.showError(err);
        });
    }

    changePool(event: any) {
        this.pool_id = event.value;

        const pool_info = this.pools.find((item: any) => {
            return item.pool_id === this.pool_id;
        });

        if (pool_info) {
            this.borrower_fee_percentage = pool_info.borrower_fee_percentage;
            this.min_borrow_amount = pool_info.min_borrow_amount;
        }
    }

    async getSignature(transaction: Transaction) {
        if (this.walletService.current_provider === 'phantom') {
            console.log('transaction', transaction);

            const response = await this.phantomService.signTransaction(transaction);

            console.log('signTransaction', response);

            return response;
        }

        return undefined;
    }

    updateSignature(signature: string) {
        const body = {
            drawdown_id: this.drawdown_id,
            blockchain_txid: signature
        };

        this.httpService.updateDrawdown(body).subscribe((res: any) => {
            this.new_drawdown_loading = false;

            this.cfAlertService.showMessage(res.message);

            this.router.navigateByUrl( '/transactions?tab=my-drawdowns');
        }, (err: HttpErrorResponse) => {
            console.error('updateSignature error');
            console.error(err);

            this.cfAlertService.showError(err);

            this.new_drawdown_loading = false;
        });
    }

    async sendTransaction(transaction: Transaction) {
        try {
            console.log(transaction);

            const res = await this.getSignature(transaction);

            console.log('res getSignature', res);

            if (!res || !res.signature)
                return;

            transaction = res;

            const connection = new Connection(networks[this.httpService.network].rpc, 'confirmed');

            console.log('transaction', transaction);

            const txnSignature = await connection.sendRawTransaction(transaction.serialize({
                requireAllSignatures: true,
                verifySignatures: true
            }));

            console.log('txn signature', txnSignature);

            this.updateSignature(txnSignature);
        }

        catch (err) {
            console.error('sendTransaction catch');
            console.error(err);

            this.cfAlertService.showError(new HttpErrorResponse({
                error: err
            }));

            this.new_drawdown_loading = false;
        }
    }
}
