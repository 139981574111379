<div class="app-lend-widget" [ngClass]="theme === 'dark' ? 'dark': ''">
    <div class="lend-widget-sub-section">
        <div class="lend-widget-sub-section-tabs">
            <div class="button-container mb-4">
                <button type="button" class="button" [ngClass]="currentTab === 'supply' ? 'active' :''"
                    (click)="changedTab('supply')">
                    Supply
                </button>
                <button type="button" class="button" [ngClass]="currentTab === 'earning' ? 'active' :''"
                    (click)="changedTab('earning')">
                    Earn
                </button>
                <button type="button" class="button" [ngClass]="currentTab === 'withdrawal' ? 'active' :''"
                    (click)="changedTab('withdrawal')">
                    Withdraw
                </button>

            </div>
        </div>

        <div class="tabs">
            <app-new-investment [pool_id]="pool_id" *ngIf="currentTab === 'supply'"></app-new-investment>
            <app-new-earning *ngIf="currentTab === 'earning'" [pool_id]="pool_id"></app-new-earning>
            <app-new-withdrawal *ngIf="currentTab === 'withdrawal'" [pool_id]="pool_id"></app-new-withdrawal>
        </div>
    </div>
</div>