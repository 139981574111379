<div class="app-new-investment" [ngClass]="theme === 'dark' ? 'dark' : ''">
    <div *ngIf="!loading">
        <div class="row">
            <cf-input-currency-dropdown [theme]="theme" [options]="paymentCurrencyDropdownOptions"
                [value]="payment_amount" [class]="'input'" label="Payment amount" [selected]="payment_currency"
                type="number" (valueChanged)="paymentAmountChanged($event)"
                (currencyChanged)="paymentCurrencyChanged($event)" [precision]="2">
            </cf-input-currency-dropdown>
            <span class="equivalent-amount">
                Available:
                {{
                balance
                | number : httpService.getPrecision(payment_currency)
                }}
                {{ payment_currency | uppercase }}
            </span>
        </div>

        <div class="row">
            <cf-dropdown [theme]="theme" [options]="durationDropdownOptions" [class]="'input'" label="Select Duration"
                [showLogo]="false" [selected]="selectedDurationItem" (change)="changeDuration($event)">
            </cf-dropdown>
        </div>

        <div class="row" *ngIf="vault_dropdown_options.length > 0">
            <cf-dropdown [theme]="theme" [options]="vault_dropdown_options" [class]="'input'" label="Select Vault"
                [selected]="selected_vault" (change)="changeVault($event)">
            </cf-dropdown>
        </div>

        <div class="row mt-2">
            <div class="new-investment-summary">
                <div class="summary-item">
                    <span class="summary-heading">Principal amount</span>
                    <span class="summary-value">{{
                        payment_amount
                        | number
                        : httpService.getPrecision(payment_currency)
                        }}
                        {{ payment_currency | uppercase }}</span>
                </div>
                <div class="summary-item">
                    <span class="summary-heading">{{ annual_interest_rate | number }}% APY
                        {{ payment_currency | uppercase }}</span>
                    <span class="summary-value">{{ annual_interest | number }}
                        {{ payment_currency | uppercase }}</span>
                </div>
                <div class="summary-item" *ngIf="cred_percentage > 0">
                    <span class="summary-heading">{{ cred_percentage | number }}% APY {{additionalYieldLabel}}
                        pts</span>
                    <span class="summary-value">{{ cred_points | number }} pts</span>
                </div>
            </div>
        </div>
        <div class="actions">
            <div *ngIf="
                    this.pool?.kyc_required === true &&
                    httpService.user.wallet_address !== '' &&
                    httpService.user?.kyc_status !== 1
                ">
                <app-kyc [pool_id]="this.pool.pool_id" />
            </div>
            <div class="action" *ngIf="
                    httpService.user.wallet_address !== '' &&
                    (!this.pool?.kyc_required ||
                        httpService.user?.kyc_status === 1)
                ">
                <div *ngIf="!new_investment_loading">
                    <cf-button label="Confirm" type="solid" (click)="createInvestment()"></cf-button>
                </div>
                <div *ngIf="new_investment_loading">
                    <cf-loader></cf-loader>
                </div>
            </div>
            <div class="action" *ngIf="httpService.user.wallet_address === ''">
                <cf-button label="Connect wallet" type="solid" (click)="connectWallet()"></cf-button>
            </div>
        </div>
    </div>
    <div class="loader" *ngIf="loading">
        <cf-loader></cf-loader>
    </div>
</div>